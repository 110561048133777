import PropTypes from 'prop-types'

export const organizationShapeOptional = PropTypes.shape({
  DBId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
})

export const organizationShape = organizationShapeOptional.isRequired

export const personShapeOptional = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  birthDate: PropTypes.string.isRequired,
  currentRole: PropTypes.string.isRequired,
  livesIn: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
})

export const personShape = personShapeOptional.isRequired