import React, { useContext, useState } from 'react'
import 'styled-components/macro'
import { AuthContext } from '../AuthContext'
import { app } from '../fbase'
import { ReactComponent as KeyIcon } from '../svg/key2.svg'
import './Account.css'

const AccountDetails = () => {
  const [ error, setError ] = useState('')

  const logout = () => {
    app.auth().signOut()
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('logging out error', error.code, error.message)
        setError(error.message)
      })
  }

  return (
    <div className="record-form" css="top: 0; right: 16px; width: 120px">
      {error && <div className="error">{error}</div>}
      <button className="action-button" onClick={logout}>
          Log out
      </button>
    </div>
  )
}

const LogInForm = () => {
  const [ createNew, setCreateNew ] = useState(false)
  const [ error, setError ] = useState('')
  const [ displayName, setDisplayName ] = useState('')
  const [ mail, setMail ] = useState('')
  const [ password, setPassword ] = useState('')

  const signup = () => {
    app.auth().createUserWithEmailAndPassword(mail, password)
      .then(() => {
        app.auth().currentUser.updateProfile({ displayName })
        setMail('')
        setPassword('')
        setDisplayName('')
        setError('')
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('creating user error', error.code, error.message)
        setError(error.message)
      })
  }

  const login = () => {
    app.auth().signInWithEmailAndPassword(mail, password)
      .then(() => {
        setMail('')
        setPassword('')
        setDisplayName('')
        setError('')
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('signing in error', error.code, error.message)
        setError(error.message)
      })
  }

  return (
    <div className="record-form">
      <div className="sign-options-buttons">
        <button
          className={`action-button ${createNew ? '' : 'action-button-toggle'}`}
          onClick={() => {
            setCreateNew(false)
            setError('')
          }}
          css="border-radius: 4px 0 0 4px"
        >
          Log in
        </button>
        <button
          className={`action-button ${createNew ? 'action-button-toggle' : ''}`}
          onClick={() => {
            setCreateNew(true)
            setError('')
          }}
          css="border-radius: 0 4px 4px 0; margin-left: -1px"
        >
          Sign up
        </button>
      </div>

      {createNew && (
        <>
          <label htmlFor="displayName">My name is:</label>
          <input type="text" name="displayName" value={displayName} onChange={
            e => setDisplayName(e.target.value)
          } />
        </>
      )}

      <label htmlFor="mail">My mail is:</label>
      <input type="text" name="mail" value={mail} onChange={e => setMail(e.target.value)} />

      <label htmlFor="password">My password is:</label>
      <input type="password" name="password" value={password} onChange={
        e => setPassword(e.target.value)
      } />

      {error && <div className="error">{error}</div>}

      <button css="margin-top: 12px; width: 100%;"
        className="action-button"
        onClick={() => createNew ? signup() : login()}
      >
        Get started
      </button>
    </div>
  )
}

export const Account = () => {
  const { currentUser } = useContext(AuthContext)
  const [ showAccount, setShowAccount ] = useState(false)
  const toggleShowAccount = () => setShowAccount(!showAccount)

  return (
    <div css="margin-left: auto">
      <button className="account" onClick={toggleShowAccount}>
        <KeyIcon fill="#999" width="16px" height="24px" />
        <span className="account-text">
          {currentUser ?
            <>{currentUser.displayName ? currentUser.displayName : currentUser.email}</>
            :
            <>sign up/in</>
          }
        </span>
      </button>

      {showAccount && (
        <div css="position: relative" >
          {currentUser ?
            <AccountDetails />
            :
            <LogInForm />
          }
        </div>
      )}
    </div>
  )
}
