/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import { Link } from 'react-router-dom'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { AuthContext } from './AuthContext'
import { addDBIds, saveRecord } from './fbase'
import { personShape, organizationShape, personShapeOptional, organizationShapeOptional } from './shapes'
import { ReactComponent as CrossIcon } from './svg/cross.svg'

const saveTenureData = (tenureData, setError, setNewRecordVisible) => {
  const err = []
  if (!tenureData.title) err.push('No title')
  if (!tenureData.startDate) err.push('No start date')
  if (tenureData.tenureFinished) {
    if (!tenureData.endDate) err.push('No end date')
  }

  if (err.length) {
    setError(err)
  } else {
    setError([])
    saveRecord('tenures', tenureData)
    setNewRecordVisible(false)
  }
}

const TenureForm = ({ person, organization, setNewRecordVisible }) => {
  const [ error, setError ] = useState('')
  const [ tenureData, setTenureData ] = useState({
    personId: person ? person.DBId : 0,
    orgId: organization ? organization.DBId : 0,
    tenureFinished: false,
    startDate: '',
    endDate: '',
    title: '',
  })
  // given: one person or one org, depending whether the form is adding
  // to career history or position in an org

  useFirestoreConnect([
    { collection: 'people', storeAs: 'people' },
    { collection: 'organizations', storeAs: 'organizations' },
  ])
  const peopleData = useSelector(state => state.firestore.data.people)
  const organizationsData = useSelector(state => state.firestore.data.organizations)

  if (!isLoaded(peopleData) || !isLoaded(organizationsData)) return null

  if (isEmpty(peopleData) || isEmpty(organizationsData)) return <div className="record-form">not enough data</div>

  const people = addDBIds(peopleData)
  const organizations = addDBIds(organizationsData)

  if (person) {
    if (tenureData.orgId === 0) setTenureData({ ...tenureData, orgId: organizations[0].DBId })
  } else {
    if (tenureData.personId === 0) setTenureData({ ...tenureData, personId: people[0].DBId })
  }

  const togglePublished = () => {
    setTenureData({ ...tenureData, published: !tenureData.published })
  }
  const toggleFinished = () => {
    setTenureData({ ...tenureData, tenureFinished: !tenureData.tenureFinished })
  }

  const onChange = event => setTenureData({ ...tenureData, [event.target.name]: event.target.value })

  return (
    <div className="record-form" onClick={e => {
      e.stopPropagation()
    }}>
      <CrossIcon fill="#999" width="12px"
        css="position: absolute; top: 0; right: 10px; cursor: pointer; line-height: 12px;"
        onClick={() => setNewRecordVisible(false)}
      />

      <label htmlFor="personId">person</label>
      {person ? (
        <div className="form-text">{person.firstName} {person.lastName}</div>
      ):(
        <select name="personId" value={tenureData.personId} onChange={onChange}>
          {people.map(person => <option key={person.DBId} value={person.DBId}>{person.firstName} {person.lastName}</option>)}
        </select>
      )
      }

      <label htmlFor="orgId">organization</label>
      {organization ? (
        <div className="form-text">{organization.name}</div>
      ) : (
        <select name="orgId" value={tenureData.orgId} onChange={onChange}>
          {organizations.map(org => <option key={org.DBId} value={org.DBId}>{org.name}</option>)}
        </select>
      )
      }

      <label htmlFor="title">title</label>
      <input type="text" name="title" value={tenureData.title} onChange={onChange} />

      <label htmlFor="startDate">start date</label>
      <input type="date" name="startDate" value={tenureData.startDate} onChange={onChange} />

      <label htmlFor="tenureFinished">tenure Finished</label>
      <input name="tenureFinished" type="checkbox" onChange={toggleFinished} checked={tenureData.tenureFinished}/>

      {tenureData.tenureFinished && (
        <>
          <label htmlFor="endDate">end date</label>
          <input type="date" name="endDate" value={tenureData.endDate} onChange={onChange} />
        </>
      )}

      <label htmlFor="notes">notes</label>
      <textarea name="notes" value={tenureData.notes} onChange={onChange} />

      <label htmlFor="published">published</label>
      <input name="published" type="checkbox" onChange={togglePublished} checked={tenureData.published}/>

      {!!error.length && error.map((err, i) => <div key={i} className="error">{err}</div>)}

      <button
        className="action-button"
        onClick={() => saveTenureData(tenureData, setError, setNewRecordVisible)}>
      save
      </button>
    </div>
  )
}
TenureForm.propTypes = {
  person: personShapeOptional,
  organization: organizationShapeOptional,
  setNewRecordVisible: PropTypes.func.isRequired,
}

const EmploymentRecordsB = ({ person }) => {
  const { userAccess } = useContext(AuthContext)
  // could be done with query - leaving as full data retrieval for now
  useFirestoreConnect([
    { collection: 'tenures', storeAs: 'tenures' },
    { collection: 'organizations', storeAs: 'organizations' },
  ])
  const recordsData = useSelector(state => state.firestore.data.tenures)
  const orgsData = useSelector(state => state.firestore.data.organizations)

  if (!isLoaded(orgsData) || !isLoaded(recordsData)) return <tr><td colSpan={4}>loading data...</td></tr>
  if (isEmpty(orgsData) || isEmpty(recordsData)) return <tr><td colSpan={4}>no data available</td></tr>

  //here all the data is present
  const records = addDBIds(recordsData).filter(record => record.personId === person.DBId)
  const orgs = addDBIds(orgsData)

  return records.map(record => {
    const org = orgs.find(org => org.DBId === record.orgId)
    if (!org) return null

    return (
      <tr key={record.DBId}>
        <td>{record.title}</td>
        <td>
          {(userAccess === 'admin' || org.published) ? (
            <Link className="link" to={'/organizations/' + org.DBId}>
              {org.name}
            </Link>
          ) : (
            <span>{org.name}</span>
          )}
        </td>
        <td>{record.startDate}</td>
        <td>{record.endDate ? record.endDate : '(current)'}</td>
      </tr>
    )
  })
}
EmploymentRecordsB.propTypes = {
  person: personShape,
}
// list of positions a person occupied
const CareerHistory = ({ person }) => {
  const { userAccess } = useContext(AuthContext)
  const [ newRecordVisible, setNewRecordVisible ] = useState(false)
  const toggleNewRecord = () => setNewRecordVisible(!newRecordVisible)

  return (
    <div>
      <div className="relation-header">
        Career History
        {userAccess === 'admin' && (
          <button className="action-button action-button-small" onClick={toggleNewRecord}>add new record</button>
        )}
        {newRecordVisible && (
          <TenureForm person={person} setNewRecordVisible={setNewRecordVisible} />
        )}
      </div>

      <table className="relation">
        <thead>
          <tr>
            <th>Title</th>
            <th>Organization</th>
            <th>Start date</th>
            <th>End date</th>
          </tr>
        </thead>
        <tbody>
          <EmploymentRecordsB person={person} />
        </tbody>
      </table>
    </div>
  )
}
CareerHistory.propTypes = {
  person: personShape,
}

const EmploymentRecords = ({ organization }) => {
  const { userAccess } = useContext(AuthContext)
  // could be done with query - leaving as full data retrieval for now
  useFirestoreConnect([
    { collection: 'tenures', storeAs: 'tenures' },
    { collection: 'people', storeAs: 'people' },
  ])
  const recordsData = useSelector(state => state.firestore.data.tenures)
  const peopleData = useSelector(state => state.firestore.data.people)

  if (!isLoaded(recordsData) || !isLoaded(peopleData)) return <tr><td colSpan={4}>loading data...</td></tr>
  if (isEmpty(recordsData) || isEmpty(peopleData)) return <tr><td colSpan={4}>no data available</td></tr>

  //here all the data is present
  const records = addDBIds(recordsData).filter(record => record.orgId === organization.DBId)
  const people = addDBIds(peopleData)

  return records.map(record => {
    const person = people.find(person => person.DBId === record.personId)
    if (!person) return null

    return (
      <tr key={record.DBId}>
        <td>{record.title}</td>
        <td>
          {(userAccess === 'admin' || person.published) ? (
            <Link className="link" to={'/people/' + person.DBId}>
              {person.firstName} {person.lastName}
            </Link>
          ) : (
            <span>{person.firstName} {person.lastName}</span>
          )}
        </td>
        <td>{record.startDate}</td>
        <td>{record.endDate ? record.endDate : '(current)'}</td>
      </tr>
    )
  })
}
EmploymentRecords.propTypes = {
  organization: organizationShape,
}

// list of positions in organisation filled be people
const PeopleInOrganization = ({ organization }) => {
  const { userAccess } = useContext(AuthContext)
  const [ newRecordVisible, setNewRecordVisible ] = useState(false)

  const toggleNewRecord = () => setNewRecordVisible(!newRecordVisible)

  return (
    <div>
      <div className="relation-header">
        People Involved
        {userAccess === 'admin' && (
          <button className="action-button action-button-small" onClick={toggleNewRecord}>add new tenure</button>
        )}
        {newRecordVisible && (
          <TenureForm organization={organization} setNewRecordVisible={setNewRecordVisible} />
        )}
      </div>

      <table className="relation">
        <thead>
          <tr>
            <th>Title</th>
            <th>Person</th>
            <th>Start date</th>
            <th>End date</th>
          </tr>
        </thead>
        <tbody>
          <EmploymentRecords organization={organization} />
        </tbody>
      </table>
    </div>
  )
}
PeopleInOrganization.propTypes = {
  organization: organizationShape,
}

export { CareerHistory, PeopleInOrganization }