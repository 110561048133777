/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from '../svg/search.svg'
import { ReactComponent as CrossIcon } from '../svg/cross.svg'
import { AuthContext } from '../AuthContext'
import { Account } from './Account'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import 'styled-components/macro'
import './NavBar.css'

const paths = {
  home: '/',
  people: '/people',
  orgs: '/organizations',
  users: '/users'
}

const pathType = path => {
  if (path === '/') return 'home'
  return Object.keys(paths).find(type => {
    return (type !== 'home' && path.startsWith(paths[type]))
  })
}

const SearchResultsBox = ({ children, setShowSearch, text = '' }) => {
  return (
    <div id="search-results" className="search-results">
      <CrossIcon fill="#999" width="12px"
        css="position: absolute; top: -4px; right: 6px; cursor: pointer; line-height: 12px;"
        onClick={() => setShowSearch(false)}
      />
      {text ? text : children}
    </div>
  )
}
SearchResultsBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  setShowSearch: PropTypes.func.isRequired,
  text: PropTypes.string,
}

const SearchResults = ({ searchText, setShowSearch }) => {
  const matchingRecords = []

  useFirestoreConnect([
    { collection: 'people', storeAs: 'people' },
    { collection: 'organizations', storeAs: 'organizations' },
  ])
  const peopleData = useSelector(state => state.firestore.data.people)
  const organizationsData = useSelector(state => state.firestore.data.organizations)

  if (searchText === '') return (
    <SearchResultsBox setShowSearch={setShowSearch} text="enter text to search" />
  )

  if (!isLoaded(peopleData) || !isLoaded(organizationsData)) return (
    <SearchResultsBox setShowSearch={setShowSearch} text="loading data..." />
  )

  if (isEmpty(peopleData) && isEmpty(organizationsData)) return (
    <SearchResultsBox setShowSearch={setShowSearch} text="is database empty?" />
  )

  for (const id in peopleData) {
    if (matchingRecords.length > 8) break
    if (
      peopleData[id].firstName.includes(searchText) ||
      peopleData[id].lastName.includes(searchText)
    ) matchingRecords.push({ ...peopleData[id], type: 'person', DBId: id })
  }
  for (const id in organizationsData) {
    if (matchingRecords.length > 8) break
    if (
      organizationsData[id].name.includes(searchText)
    ) matchingRecords.push({ ...organizationsData[id], type: 'organization', DBId: id })
  }

  if (!matchingRecords.length) return (
    <SearchResultsBox setShowSearch={setShowSearch} text="no matching records found" />
  )

  return (
    <SearchResultsBox setShowSearch={setShowSearch}>
      {matchingRecords.map(record => (
        <div key={record.DBId}>
          {record.type === 'organization' ? (
            <Link onClick={() => setShowSearch(false)} className="link" to={'/organizations/' + record.DBId}>
              {record.name}
            </Link>
          ) : (
            <Link onClick={() => setShowSearch(false)} className="link" to={'/people/' + record.DBId}>
              {record.firstName} {record.lastName}
            </Link>
          )}
        </div>
      ))}
    </SearchResultsBox>
  )
}
SearchResults.propTypes = {
  searchText: PropTypes.string.isRequired,
  setShowSearch: PropTypes.func.isRequired,
}

const NavBar = ({ history }) => {
  const { userAccess } = useContext(AuthContext)
  const [ showPage, setShowPage ] = useState(pathType(history.location.pathname))
  const [ showSearch, setShowSearch ] = useState(false)
  const [ searchText, setSearchText ] = useState('')

  return (
    <div className="navbar">
      <div className="title">
        Wikilection
      </div>
      <select className="menu" onChange={e => {
        history.push(paths[e.target.value])
        setShowPage(e.target.value)
      }}
      value={showPage}
      >
        <option value="home">Home</option>
        <option value="people">People</option>
        <option value="orgs">Organizations</option>
        {(userAccess === 'admin') && (
          <option value="users">Users</option>
        )}
      </select>

      <div css="position: relative">
        <input className="search" name="search"
          onChange={e => setSearchText(e.target.value)}
          onFocus={() => setShowSearch(true)}
          value={searchText}
        />
        {showSearch && <SearchResults setShowSearch={setShowSearch} searchText={searchText} />}
      </div>
      <SearchIcon fill="#999" width="16px" css="margin-left: -24px; z-index: 1" />
      <Account />
    </div>
  )
}
NavBar.propTypes = {
  history: PropTypes.object.isRequired,
}

export default withRouter(NavBar)
