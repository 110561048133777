/* eslint-disable jsx-a11y/no-onchange */
import React, { useContext } from 'react'
import 'styled-components/macro'
import { AuthContext } from '../AuthContext'
import { saveUser } from '../fbase'

const Users = () => {
  const { userAccess, users } = useContext(AuthContext)

  if (userAccess !== 'admin') return <div>you are not an admin</div>

  return (
    <div className="record">
      <h1>
        Users of Wikilection
      </h1>
      <table className="relation">
        <thead>
          <tr>
            <th>user</th>
            <th>access type</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(users).map(uid => (
            <tr key={uid}>
              <td>{users[uid].name}</td>
              <td>
                <select value={users[uid].access} onChange={e => saveUser(uid, 'access', e.target.value)}>
                  <option value="ro">read-only</option>
                  <option value="admin">admin</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Users