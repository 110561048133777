import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import cloneDeep from 'lodash/cloneDeep'

const saveUser = (uid, field, value) => {
  db.collection('wikilection').doc('users').set({
    [uid]: { [field]: value }
  }, { merge: true })
    // eslint-disable-next-line no-console
    .then(() => console.log('Saved user:', uid, field, value))
    .catch(err => {
      console.error('Error saving user: ', err)
    })
}

const addDBIds = records =>  Object.keys(records).map(id => {
  if (records[id]) return { ...cloneDeep(records[id]), DBId: id }
  return null
}).filter(record => record)

const saveRecord = (collection, record, field = undefined) => {
  if (!record.DBId) {
    db.collection(collection).add(record)
      .catch(error => console.error('Error creating record', record, error))
  } else {
    if (field) {
      db.collection(collection).doc(record.DBId).set({
        [field]: record[field],
      }, { merge: true }
      )
        .catch(error => console.error('Error saving record', record, error))
    } else {
      db.collection(collection).doc(record.DBId).set(record, { merge: true })
        .catch(error => console.error('Error saving record', record, error))
    }
  }
}

const saveFile = async (id, file, recordData, setRecordData) => {
  const path = 'img/'+ id + '.' + file.name
  const storageRef = firebase.storage().ref(path)
  const task = storageRef.put(file)
  task.on('state_changed', function progress(snapshot) {
    const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    setRecordData({ ...recordData, img: `Uploading: ${percentage}%` })
  }, function error(err) {
    // eslint-disable-next-line no-console
    console.log('Error saving file', err)
  }, function complete() {
    // eslint-disable-next-line no-console
    console.log('saveFile done!')
    storageRef.getDownloadURL()
      .then(img => {
        setRecordData({ ...recordData, img, imgName: id + '.' + file.name })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log('Error getting URL for the image', err)
      })
  })
}

const removeFile = (orgData, saveRecord) => {
  const storageRef = firebase.storage().ref('img/')
  const imgRef = storageRef.child(orgData.imgName)
  imgRef.delete()
    .then(() => {
      orgData.img = 'none'
      orgData.imgRef = ''
      saveRecord('organizations', orgData)
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log('Error removing img', err))
}

const firebaseConfig = {
  apiKey: 'AIzaSyD4bxWPBBbdrAi527gj8qGuwpOqlRa_G-k',
  authDomain: 'wikilection.firebaseapp.com',
  databaseURL: 'https://wikilection.firebaseio.com',
  projectId: 'wikilection',
  storageBucket: 'wikilection.appspot.com',
  messagingSenderId: '978892216248',
  appId: '1:978892216248:web:32f6241b7334bff41a214d'
}

const app = firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()

export { app, db, firebase, addDBIds, saveRecord, saveFile, removeFile, saveUser }
