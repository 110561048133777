import React, { useEffect, useState } from 'react'
import { db, app } from './fbase'
import PropTypes from 'prop-types'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [ currentUser, setCurrentUser ] = useState(null)
  const [ userData, setUserData ] = useState({})
  const [ userAccess, setUserAccess ] = useState('ro')
  const [ users, setUsers ] = useState({})

  const saveUserData = data => {

    if (!currentUser) return

    db.collection('userData').doc(currentUser.uid).set({
      [data.key]: data.val,
    }, { merge: true })
      .catch(function(error) {
        console.error('Error writing document: ', error)
      })
  }

  const setCurrentUserF = user => {
    if (user) {
      const uid = user.uid

      db.collection('userData').doc(uid).onSnapshot(userData => {
        if (userData.exists) {
          setUserData(userData.data())
        } else {
          db.collection('userData').doc(uid).set({ singUpDate: new Date().toISOString() })
            .catch(function(error) {
              console.error('Error writing document: ', error)
            })
        }
      })

      db.collection('wikilection').doc('users').get()
        .then(doc => {
          if (doc.exists) {
            const users = doc.data()
            if (uid in users) {
              setUserAccess(users[uid].access)
            } else {
              db.collection('wikilection').doc('users').set({
                [uid]: {
                  access: 'ro',
                  name: user.email
                }
              }, { merge: true })
            }
          } else {
            db.collection('wikilection').doc('users').set({
              [uid]: {
                access: 'ro',
                name: user.email
              }
            }, { merge: true })
          }
        })
        .catch(function(error) {
          // eslint-disable-next-line no-console
          console.log('Error users doc', error)
        })

    }

    setCurrentUser(user)
  }

  useEffect(() => {
    app.auth().onAuthStateChanged(setCurrentUserF)
  }, [])

  useEffect(() => {
    db.collection('wikilection').doc('users').onSnapshot(users => {
      if (users.exists) {
        setUsers(users.data())
      } else {
        console.error('No users doc')
      }
    })
  }, [])

  return (
    <AuthContext.Provider value={{ currentUser, userAccess, userData, saveUserData, users }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}