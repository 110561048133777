import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { personShape, organizationShape } from '../shapes'


const EditButton = ({ form, record }) => {
  const [ editRecordVisible, setEditRecordVisible ] = useState(false)
  const toggleEditRecord = () => setEditRecordVisible(!editRecordVisible)
  const Form = form

  return (
    <div className="record-controls">
      <button className="action-button action-button-small" onClick={e => {
        e.stopPropagation()
        toggleEditRecord()
      }}
      >
        edit record
      </button>
      {editRecordVisible && (
        <Form record={record} close={toggleEditRecord} />
      )}
    </div>
  )
}
EditButton.propTypes = {
  form: PropTypes.func.isRequired,
  record: PropTypes.oneOfType([
    personShape,
    organizationShape,
  ]),
}

export default EditButton