/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState } from 'react'
import 'styled-components/macro'
import PropTypes from 'prop-types'
import { CareerHistory } from './Relations'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { AuthContext } from './AuthContext'
import { addDBIds, saveRecord, db, saveFile, removeFile } from './fbase'
import { Link, useParams, withRouter } from 'react-router-dom'
import { personShape } from './shapes'
import EditButton from './components/EditButton'
import Dropzone from 'react-dropzone'
import { ReactComponent as CrossIcon } from './svg/cross.svg'

const Person = ({ person }) => {
  const { userAccess } = useContext(AuthContext)

  return (
    <div className="record">
      <Link className="link" to="/people">&lt;&lt;&nbsp;back</Link>

      <div className="record-header">
        <div>
          {person.firstName} {person.lastName}
        </div>
        {userAccess === 'admin' && <EditButton form={PersonForm} record={person} />}
      </div>

      <div className="record-details-main">
        <div className="record-details">
          <ul>
            <li>Current role: {person.currentRole}</li>
            <li>Lives in: {person.livesIn}</li>
            <li>Birthdate: {person.birthDate}</li>
          </ul>
        </div>
        {person.img.startsWith('https') ? (
          <img
            css="width: 120px; height: 120px; margin: 12px"
            src={person.img}
            alt="person avatar"
          />
        ) : (
          <div className="record-photo record-photo-empty">
            photo
          </div>
        )}
      </div>

      <div className="record-description">
        {person.description}
      </div>

      <CareerHistory person={person} />

    </div>
  )
}
Person.propTypes ={
  person: personShape,
}

const PersonRow = ({ history, person }) => {
  const { userAccess } = useContext(AuthContext)

  return (
    <tr>
      <td onClick={() => history.push('/people/' + person.DBId)}>
        {userAccess === 'admin' ? (
          <>
            <div>
              <input name="published" type="checkbox" disabled checked={person.published} css="margin-right: 12px" />
              {person.firstName} {person.lastName}
            </div>
            <EditButton form={PersonForm} record={person} />
          </>
        ) : (
          <div>
            {person.firstName} {person.lastName}
          </div>
        )
        }
      </td>
    </tr>
  )
}
PersonRow.propTypes ={
  history: PropTypes.object.isRequired,
  person: personShape,
}

const PeopleList = () => {
  const { userAccess } = useContext(AuthContext)
  useFirestoreConnect([{ collection: 'people', storeAs: 'people' }])
  const recordsDB = useSelector(state => state.firestore.data.people)

  if (!isLoaded(recordsDB)) return <tr>
    <td>loading...</td>
  </tr>
  if (isEmpty(recordsDB)) return <tr>
    <td>no records found</td>
  </tr>

  let records = addDBIds(recordsDB)

  if (userAccess !== 'admin') records = records.filter(person => person.published)

  if (!records.length) return <tr>
    <td>no published records found</td>
  </tr>

  const PersonRowLinked = withRouter(PersonRow)

  return records.map(person =>
    <PersonRowLinked
      key={person.DBId}
      person={person}
    />
  )
}

const PersonForm = ({ record: person, close }) => {
  const [ personData, setPersonData ] = useState(person)

  const togglePublished = () => {
    setPersonData({ ...personData, published: !personData.published })
  }

  const onChange = event => setPersonData({ ...personData, [event.target.name]: event.target.value })

  return (
    <div className="record-form" onClick={e => {
      e.stopPropagation()
    }}>
      <CrossIcon fill="#999" width="12px"
        css="position: absolute; top: 0; right: 10px; cursor: pointer; line-height: 12px;"
        onClick={close}
      />

      <Dropzone onDrop={acceptedFiles => {
        saveFile(person.DBId, acceptedFiles[0], personData, setPersonData)
        setPersonData({ ...personData, img: 'Uploading started...' })
      }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <section>
            <div {...getRootProps()} css="display: flex; justify-content: center">
              <input {...getInputProps()} />

              <div
                className="record-photo"
                css={`border: 2px dashed ${isDragActive ? 'red' : 'grey'}`}
              >
                {personData.img === 'none' && (
                  <div className="record-photo-empty">
                    Drag&#39;n&#39;drop some files here, or click to select files
                  </div>
                )}

                {personData.img.startsWith('Uploading') && (
                  <div className="record-photo-empty">
                    Uploading...
                  </div>
                )}

                {personData.img.startsWith('https') && (
                  <div css="display: flex; flex-direction: column">
                    <img
                      css="width: 120px; height: 120px"
                      src={personData.img}
                      alt="organization avatar"
                    />
                    <button className="action-button action-button-small"
                      onClick={e => {
                        e.stopPropagation()
                        removeFile(personData, saveRecord, 'people')
                      }}
                    >
                      delete
                    </button>
                  </div>
                )}

              </div>
            </div>
          </section>
        )}
      </Dropzone>

      <label htmlFor="firstName">first name</label>
      <input type="text" name="firstName" value={personData.firstName} onChange={onChange} />

      <label htmlFor="lastName">last name</label>
      <input type="text" name="lastName" value={personData.lastName} onChange={onChange} />

      <label htmlFor="birthDate">birth date</label>
      <input type="date" name="birthDate" value={personData.birthDate} onChange={onChange} />

      <label htmlFor="currentRole">current role</label>
      <input type="text" name="currentRole" value={personData.currentRole} onChange={onChange} />

      <label htmlFor="livesIn">lives in</label>
      <input type="text" name="livesIn" value={personData.livesIn} onChange={onChange} />

      <label htmlFor="description">description</label>
      <textarea name="description" value={personData.description} onChange={onChange} />

      <label htmlFor="published">published</label>
      <input name="published" type="checkbox" onChange={togglePublished} checked={personData.published}/>

      <button className="action-button" onClick={() => {
        saveRecord('people', personData)
        close()
      }}>
        save
      </button>
    </div>
  )
}
PersonForm.propTypes ={
  record: personShape,
  close: PropTypes.func.isRequired,
}

const People = () => {
  const { userAccess } = useContext(AuthContext)
  const [ newRecordVisible, setNewRecordVisible ] = useState(false)
  const newPersonRecord = {
    firstName: '',
    lastName: '',
    birthDate: '',
    currentRole: '',
    livesIn: '',
    description: '',
    img: 'none',
    published: false,
  }
  const toggleNewRecord = () => setNewRecordVisible(!newRecordVisible)
  const [ currentPerson, setCurrentPerson ] = useState(null)
  let { personId } = useParams()

  if (currentPerson) {
    if (personId && personId !== currentPerson.DBId) setCurrentPerson(null)
    return <Person person={currentPerson} />
  }

  if (personId) {
    db.collection('people').doc(personId).onSnapshot(
      person => {
        setCurrentPerson({ ...person.data(), DBId: personId })
      })
  }

  return (
    <div className="record">
      <div className="header-button">
        <h1>People</h1>
        {userAccess === 'admin' && (
          <button className="action-button action-button-small" onClick={toggleNewRecord}>add new person</button>
        )}
        {newRecordVisible && (
          <PersonForm record={newPersonRecord} close={toggleNewRecord} />
        )}
      </div>

      <table className="record-table">
        <tbody>
          <PeopleList />
        </tbody>
      </table>
    </div>
  )
}

export default People