/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState } from 'react'
import 'styled-components/macro'
import PropTypes from 'prop-types'
import { PeopleInOrganization } from './Relations'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { AuthContext } from './AuthContext'
import { addDBIds, saveRecord, db, saveFile, removeFile } from './fbase'
import { Link, useParams, withRouter } from 'react-router-dom'
import { organizationShape } from './shapes.js'
import EditButton from './components/EditButton'
import Dropzone from 'react-dropzone'
import { ReactComponent as CrossIcon } from './svg/cross.svg'

const Organization = ({ organization }) => {
  const { userAccess } = useContext(AuthContext)

  return (
    <div className="record">
      <Link className="link" to="/organizations">&lt;&lt;&nbsp;back</Link>

      <div className="record-header">
        <div>
          {organization.name}
        </div>
        {userAccess === 'admin' && <EditButton form={OrganizationForm} record={organization} />}
      </div>

      <div className="record-details-main">
        <div className="record-details">
          <ul>
            <li>Type: {organization.type}</li>
            <li>Country: {organization.country}</li>
            <li>Website: <a href="{org.website}">{organization.website}</a></li>
          </ul>
        </div>
        {organization.img.startsWith('https') ? (
          <img
            css="width: 120px; height: 120px; margin: 12px"
            src={organization.img}
            alt="organization avatar"
          />
        ) : (
          <div className="record-photo record-photo-empty">
            photo
          </div>
        )}
      </div>
      <div className="record-description">
        {organization.description}
      </div>

      <PeopleInOrganization organization={organization} />

    </div>
  )
}
Organization.propTypes = {
  organization: organizationShape,
}

const OrganizationForm = ({ record: organization, close }) => {
  if (!('img' in organization)) organization.img = 'none'
  const [ orgData, setOrgData ] = useState(organization)

  const togglePublished = () => {
    setOrgData({ ...orgData, published: !orgData.published })
  }

  const onChange = event => setOrgData({ ...orgData, [event.target.name]: event.target.value })

  return (
    <div className="record-form" onClick={e => {
      e.stopPropagation()
    }}>
      <CrossIcon fill="#999" width="12px"
        css="position: absolute; top: 0; right: 10px; cursor: pointer; line-height: 12px;"
        onClick={close}
      />

      <Dropzone onDrop={acceptedFiles => {
        saveFile(organization.DBId, acceptedFiles[0], orgData, setOrgData)
        setOrgData({ ...orgData, img: 'Uploading started...' })
      }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <section>
            <div {...getRootProps()} css="display: flex; justify-content: center">
              <input {...getInputProps()} />

              <div
                className="record-photo"
                css={`border: 2px dashed ${isDragActive ? 'red' : 'grey'}`}
              >
                {orgData.img === 'none' && (
                  <div className="record-photo-empty">
                    Drag&#39;n&#39;drop some files here, or click to select files
                  </div>
                )}

                {orgData.img.startsWith('Uploading') && (
                  <div className="record-photo-empty">
                    Uploading...
                  </div>
                )}

                {orgData.img.startsWith('https') && (
                  <div css="display: flex; flex-direction: column">
                    <img
                      css="width: 120px; height: 120px"
                      src={orgData.img}
                      alt="organization avatar"
                    />
                    <button className="action-button action-button-small"
                      onClick={e => {
                        e.stopPropagation()
                        removeFile(orgData, saveRecord, 'organizations')
                      }}
                    >
                      delete
                    </button>
                  </div>
                )}

              </div>
            </div>
          </section>
        )}
      </Dropzone>


      <label htmlFor="name">name</label>
      <input type="text" name="name" value={orgData.name} onChange={onChange} />

      <label htmlFor="type">type</label>
      <input type="text" name="type" value={orgData.type} onChange={onChange} />

      <label htmlFor="startDate">startDate</label>
      <input type="date" name="startDate" value={orgData.startDate} onChange={onChange} />

      <label htmlFor="country">country</label>
      <input type="text" name="country" value={orgData.country} onChange={onChange} />

      <label htmlFor="website">website</label>
      <input type="text" name="website" value={orgData.website} onChange={onChange} />

      <label htmlFor="description">description</label>
      <textarea name="description" value={orgData.description} onChange={onChange} />

      <label htmlFor="published">published</label>
      <input name="published" type="checkbox" onChange={togglePublished} checked={orgData.published}/>

      <button className="action-button" onClick={() => {
        saveRecord('organizations', orgData)
        close()
      }}>
        save
      </button>
    </div>
  )
}
OrganizationForm.propTypes = {
  record: organizationShape,
  close: PropTypes.func.isRequired,
}

const OrganizationRow = ({ history, organization }) => {
  const { userAccess } = useContext(AuthContext)

  return (
    <tr>
      <td onClick={() => history.push('/organizations/' + organization.DBId)}>
        {organization.name}
        {userAccess === 'admin' && (
          <EditButton form={OrganizationForm} record={organization} />
        )}
      </td>
    </tr>
  )
}
OrganizationRow.propTypes = {
  history: PropTypes.object.isRequired,
  organization: organizationShape,
}

const OrganizationsList = () => {
  const { userAccess } = useContext(AuthContext)
  useFirestoreConnect([{ collection: 'organizations', storeAs: 'organizations' }])
  const recordsDB = useSelector(state => state.firestore.data.organizations)

  if (!isLoaded(recordsDB)) return <tr>
    <td>loading...</td>
  </tr>
  if (isEmpty(recordsDB)) return  <tr>
    <td>no records found</td>
  </tr>

  let records = addDBIds(recordsDB)

  if (userAccess !== 'admin') records = records.filter(organization => organization.published)

  if (!records.length) return <tr>
    <td>no published records found</td>
  </tr>

  const OrganizationRowLinked = withRouter(OrganizationRow)

  return records.map(organization =>
    <OrganizationRowLinked
      key={organization.DBId}
      organization={organization}
    />
  )
}

const Organizations = () => {
  const { userAccess } = useContext(AuthContext)
  const [ newRecordVisible, setNewRecordVisible ] = useState(false)
  const [ currentOrg, setCurrentOrg ] = useState(null)
  const toggleNewRecord = () => setNewRecordVisible(!newRecordVisible)
  const newOrgRecord = {
    DBId: '',
    name: '',
    type: '',
    startDate: '',
    country: '',
    img: 'none',
    website: 'https://',
    description: '',
    published: false,
  }
  let { organizationId } = useParams()

  if (currentOrg) return <Organization organization={currentOrg} />

  if (organizationId) {
    db.collection('organizations').doc(organizationId).onSnapshot(
      org => {
        setCurrentOrg({ ...org.data(), DBId: organizationId })
      })
  }

  return (
    <div className="record">
      <div className="header-button">
        <h1>Organizations</h1>
        {userAccess === 'admin' && (
          <button className="action-button action-button-small" onClick={toggleNewRecord}>add new organization</button>
        )}
        {newRecordVisible && (
          <OrganizationForm record={newOrgRecord} close={toggleNewRecord} />
        )}
      </div>

      <table className="record-table">
        <tbody>
          <OrganizationsList />
        </tbody>
      </table>
    </div>
  )
}

export { Organizations }