/* eslint-disable import/no-unused-modules */
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { createFirestoreInstance } from 'redux-firestore'
import { firebase } from './fbase'
import createStore from './createStore'
import { ReduxFirestoreProvider } from 'react-redux-firebase'
import { Provider } from 'react-redux'

const store = createStore()

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true,
  },
  dispatch: store.dispatch,
  createFirestoreInstance
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReduxFirestoreProvider {...rrfProps}>
        <App />
      </ReduxFirestoreProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

